import { Board, Point, Color, WallType, Direction, Robot, Wall } from "./gen/types";

export function toString(board: Board): string {
  const goalPos = pointToPos(board.target.point);
  const goalCol = colorToIdx(board.target.color);
  const red = pointToPos(board.robots.red.point);
  const green = pointToPos(board.robots.green.point);
  const blue = pointToPos(board.robots.blue.point);
  const yellow = pointToPos(board.robots.yellow.point);

  const walls = board.walls
    .map((w) => {
      return `${pointToPos(w.point)}:${wallToString(w.wall)}`;
    })
    .join("-");

  // <goal pos> <goal color> <red robot> <green robot> <blue robot> <yellow robot> <walls>
  // ./run.sh 10 1 0 1 2 3 0:S-4:E

  return `${goalPos} ${goalCol} ${red} ${green} ${blue} ${yellow} ${walls}`;
}

function robot(point: Point, color: Color): Robot {
  return {
    point,
    color,
    facing: Direction.Right,
  };
}

function point(x: number, y: number): Point {
  return { x, y };
}

function pointFromPos(pos: number) {
  return point(pos % 16, Math.floor(pos / 16));
}

function colorFromNum(n: string): Color {
  switch (n) {
    case "0":
      return Color.Red;
    case "1":
      return Color.Green;
    case "2":
      return Color.Blue;
    case "3":
      return Color.Yellow;
    default:
      throw new Error("invalid color " + n);
  }
}

export function fromString(boardId:string, str: string): Board {
  const parts = str.split(" ");
  if (parts.length !== 7) throw new Error("str needs 7 parts");

  const walls: Wall[] = parts[6].split("-").map((x) => {
    const [p, w] = x.split(":");
    return {
      point: pointFromPos(parseInt(p)),
      wall: wallFromString(w),
    };
  });

  return {
    boardId,
    walls,
    robots: {
      red: robot(pointFromPos(parseInt(parts[2])), Color.Red),
      green: robot(pointFromPos(parseInt(parts[3])), Color.Green),
      blue: robot(pointFromPos(parseInt(parts[4])), Color.Blue),
      yellow: robot(pointFromPos(parseInt(parts[5])), Color.Yellow),
    },
    boardSize: { w: 16, h: 16 },
    target: {
      point: pointFromPos(parseInt(parts[0])),
      color: colorFromNum(parts[1]),
    },
  };
}

function pointToPos({ x, y }: Point): number {
  return x + y * 16;
}

function colorToIdx(color: Color): number {
  switch (color) {
    case Color.Red:
      return 0;
    case Color.Green:
      return 1;
    case Color.Blue:
      return 2;
    case Color.Yellow:
      return 3;
  }
}

function wallToString(d: WallType): string {
  switch (d) {
    case WallType.Bl:
      return "SW";
    case WallType.Br:
      return "SE";
    case WallType.Tl:
      return "NW";
    case WallType.Tr:
      return "NE";
  }
}

function wallFromString(d: string): WallType {
  switch (d) {
    case "SW":
      return WallType.Bl;
    case "SE":
      return WallType.Br;
    case "NW":
      return WallType.Tl;
    case "NE":
      return WallType.Tr;
    default:
      throw new Error("Invalid wall type " + d);
  }
}
