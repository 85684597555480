export interface Game {
  id: string;
  state: GameState;
  round: Round | null;
  config: Config;
  rematch: Rematch | null;
  scores: { [dynamic: string]: Score };
  players: { [dynamic: string]: string };
}
export interface Rematch {
  gameId: string;
  uid: string;
}
export interface Round {
  id: string;
  best: Sequence | null;
  timestamp: number;
  board: Board;
  optimal: number;
}
export interface Puzzle {
  id: string;
  board: Board;
  optimal: number;
}
export interface Config {
  roundStartDelayMs: number;
  roundFinishDelayMs: number;
  numberOfPointsToWin: number;
  revealSolutions: boolean;
  scoringType: ScoringType;
}
export interface Sequence {
  uid: string;
  timestamp: number;
  moves: Move[];
}
export interface Move {
  direction: Direction;
  color: Color;
}
export interface Board {
  boardId: string;
  walls: Wall[];
  robots: Robots;
  boardSize: Size;
  target: Target;
}
export interface Robots {
  red: Robot;
  green: Robot;
  blue: Robot;
  yellow: Robot;
}
export interface Point {
  x: number;
  y: number;
}
export interface Size {
  w: number;
  h: number;
}
export interface Score {
  uid: string;
  points: number;
}
export interface Target {
  point: Point;
  color: Color;
}
export interface Robot {
  point: Point;
  color: Color;
  facing: Direction;
}
export interface Wall {
  point: Point;
  wall: WallType;
}
export interface User {
  uid: string;
  displayName: string;
  photoURL: string;
}
export interface GameState {
  timestamp: number;
  state: State;
}
export interface Flags {
  now: number;
  prefs: UserPrefs;
  viewport: Size;
  sounds: boolean;
  notifications: boolean;
  emojis: string[];
}
export interface UserPrefs {
  theme: Theme;
}
export interface MoveToSubmit {
  gameId: string;
  moves: Move[];
}
export interface RoundStartDelayChanged {
  gameId: string;
  ms: number;
}
export interface NumRoundsToWinChanged {
  gameId: string;
  num: number;
}
export interface RoundFinishDelayChanged {
  gameId: string;
  ms: number;
}
export interface RevealSolutionsChanged {
  gameId: string;
  reveal: boolean;
}
export interface ScoringTypeChanged {
  gameId: string;
  scoringType: ScoringType;
}
export interface AddMessage {
  gameId: string;
  message: string;
}

export enum WallType {
  Tr = "Tr",
  Tl = "Tl",
  Br = "Br",
  Bl = "Bl",
}
export enum Color {
  Red = "Red",
  Green = "Green",
  Blue = "Blue",
  Yellow = "Yellow",
}
export enum Direction {
  Up = "Up",
  Down = "Down",
  Left = "Left",
  Right = "Right",
}
export enum State {
  GameNotStarted = "GameNotStarted",
  RoundStarting = "RoundStarting",
  RoundInProgress = "RoundInProgress",
  RoundFinishing = "RoundFinishing",
  RoundFinished = "RoundFinished",
  GameFinished = "GameFinished",
}
export enum Theme {
  Dark = "Dark",
}
export enum SequenceState {
  Best = "Best",
  Optimal = "Optimal",
  Undistinguished = "Undistinguished",
}
export enum ScoringType {
  OnePointPerGame = "OnePointPerGame",
  OnePointPerMove = "OnePointPerMove",
}

export type Event =
  | { type: "Message"; id: string; uid: string; message: string }
  | { type: "GameCreated"; id: string; uid: string }
  | { type: "PlayerJoined"; id: string; uid: string }
  | {
      type: "SequenceSubmitted";
      id: string;
      uid: string;
      state: SequenceState;
      moves: Move[];
    }
  | {
      type: "RoundStarted";
      id: string;
      uid: string;
      num: number;
      boardId: string;
    }
  | {
      type: "RoundWon";
      id: string;
      winner: string;
      boardId: string;
      optimal: boolean;
    };
