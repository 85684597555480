// import * as firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/database";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';


import { newGame } from "./game";
import { Puzzle, SequenceState, Move, User, State, Game, Sequence, Round, Event, ScoringType } from "./gen/types";
import * as BoardFn from "./board";

export {
  rematch,
  fetchGame,
  createGame,
  createPuzzle,
  submitMoves,
  startNextRound,
  checkStartNextRound,
  updateUser,
  fetchUser,
  nudge,
  updateRoundStartDelay,
  updateRoundFinishDelay,
  updateNumRoundsToWin,
  updateRevealSolutions,
  addMessage,
  fetchEvents,
  fetchActiveGame,
  fetchPuzzle,
  scoringTypeChanged
};

async function fetchActiveGame(callback: (a0: string) => void): Promise<void> {
  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const snap = await firebase.database().ref("active").once("value");
  const active = snap.val();

  log(`got active game ${active && active.gameId}`);

  if (active && active.gameId) {
    callback(active.gameId);
  }
}

async function addMessage(gameId: string, message: string) {
  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const game = await getGame(gameId);
  if (!game) return log("game not found " + gameId);

  const event: Event = {
    type: "Message",
    id: "",
    uid: user.uid,
    message,
  };

  dbAddEvent(gameId, event);
}

function dbAddEvent(gameId: String, event: Event) {
  const ref = firebase.database().ref(`events/${gameId}`);

  const eventId = ref.push().key;
  if (!eventId) return log("unable to generate event id");

  return firebase
    .database()
    .ref()
    .update({
      [`events/${gameId}/${eventId}`]: { ...event, id: eventId },
    });
}

async function rematch(gameId: string) {
  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const nextGame = await dbCreateGame(user);

  gameTransaction(gameId, (game) => {
    if (game.state.state == State.GameFinished && !game.rematch) {
      return { ...game, rematch: { uid: user.uid, gameId: nextGame.id } };
    }
  });
}

async function updateRevealSolutions(gameId: string, val: boolean) {
  // log("db: updateRevealSolutions " + gameId);

  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const game = await getGame(gameId);
  if (!game) return log("game not found " + gameId);

  if (game.state.state == State.GameNotStarted) {
    firebase.database().ref(`games/${gameId}/config/revealSolutions`).set(val);
  }
}

async function scoringTypeChanged(gameId: string, scoringType: ScoringType) {
  // log("db: updateRoundStartDelay " + gameId);

  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const game = await getGame(gameId);
  if (!game) return log("game not found " + gameId);

  const num = scoringType == ScoringType.OnePointPerGame ? 10 : 100

  if (game.state.state == State.GameNotStarted) {
    firebase.database().ref(`games/${gameId}/config/scoringType`).set(scoringType);
    firebase.database().ref(`games/${gameId}/config/numberOfPointsToWin`).set(num);
  }
}

async function updateNumRoundsToWin(gameId: string, num: number) {
  // log("db: updateRoundStartDelay " + gameId);

  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const game = await getGame(gameId);
  if (!game) return log("game not found " + gameId);

  if (game.state.state == State.GameNotStarted) {
    firebase.database().ref(`games/${gameId}/config/numberOfPointsToWin`).set(num);
  }
}

async function updateRoundStartDelay(gameId: string, delay: number) {
  // log("db: updateRoundStartDelay " + gameId);

  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const game = await getGame(gameId);
  if (!game) return log("game not found " + gameId);

  if (game.state.state == State.GameNotStarted) {
    firebase.database().ref(`games/${gameId}/config/roundStartDelayMs`).set(delay);
  }
}

async function updateRoundFinishDelay(gameId: string, delay: number) {
  // log("db: updateRoundFinishDelay " + gameId);

  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const game = await getGame(gameId);
  if (!game) return log("game not found " + gameId);

  if (game.state.state == State.GameNotStarted) {
    firebase.database().ref(`games/${gameId}/config/roundFinishDelayMs`).set(delay);
  }
}

function updateUser(user: User) {
  // const user = firebase.auth().currentUser;
  // if (!user) {
  //   return log("unable to get current user");
  // }

  log("db: update user");

  const userToInsert: User = {
    uid: user.uid,
    displayName: user.displayName || "",
    photoURL: user.photoURL || "",
  }; // make sure we don't persist everthing

  return firebase
    .database()
    .ref()
    .update({
      [`users/${user.uid}`]: userToInsert,
    });
}

async function startNextRound(gameId: string): Promise<void> {
  const logger = createLogger("🎬 start");

  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  const boardSnap = await firebase.database().ref("solutions").orderByChild("random").limitToFirst(1).startAt(Math.random()).once("value");
  const boardVal: any = Object.values(boardSnap.val())[0];
  logger("o" + boardVal.length);
  // console.log(boardVal.solution);

  gameTransaction(
    gameId,
    (game) => {
      if (!game) return game;

      if (game.state.state !== State.GameNotStarted && game.state.state !== State.RoundFinished) {
        return logger(`cannot start game in state ${game.state.state}`);
      }

      logger(stateTransition(game.state.state, State.RoundStarting, ""));

      const roundId = firebase.database().ref(`games/${gameId}/scores`).push().key || String(Math.random()).slice(2);

      const round: Round = {
        id: roundId,
        best: null,
        timestamp: serverTimestamp(),
        board: BoardFn.fromString(String(boardVal.random).slice(2), boardVal.board),
        optimal: boardVal.length,
      };

      const state = {
        state: State.RoundStarting,
        timestamp: serverTimestamp(),
      };

      return {
        ...game,
        state,
        round,
      };
    },
    (snap) => {
      const game: Game | null = snap && snap.val();
      if (!game) return;

      dbAddEvent(game.id, {
        type: "RoundStarted",
        uid: user.uid,
        id: "",
        num: Object.keys(game.scores || {}).length + 1,
        boardId: String(boardVal.random).slice(2),
      });
    }
  );
}

async function checkStartNextRound(gameId: string): Promise<void> {
  const logger = createLogger("🏁🏁🏁 actual start");

  const user = firebase.auth().currentUser;
  if (!user) return log("user is null");

  // TODO: might wanna check the state here...

  gameTransaction(gameId, (game) => {
    if (!game) return game;

    if (game.state.state !== State.RoundStarting) {
      return logger(`cannot start game in state ${game.state.state}`);
    }

    logger(stateTransition(game.state.state, State.RoundInProgress, ""));

    const state = {
      state: State.RoundInProgress,
      timestamp: serverTimestamp(),
    };

    return {
      ...game,
      state,
    };
  });
}

let eventsRef: firebase.database.Reference | null = null;

function fetchEvents(gameId: string, eventsFetchedCb: (a0: Event[]) => void, eventAddedCb: (a0: Event) => void): void {
  const logger = createLogger("🎭 fetch events");

  if (eventsRef) eventsRef.off("child_added");

  eventsRef = firebase.database().ref("events").child(gameId);

  eventsRef.once("value", (s) => {
    logger("once value");

    if (!eventsRef) return;

    const startKey = eventsRef.push().key;

    eventsFetchedCb(Object.values(s.val()));

    eventsRef
      .orderByKey()
      .startAt(startKey)
      .on("child_added", (s) => {
        logger("child_added");
        eventAddedCb(s.val());
      });
  });
}

let gameRef: firebase.database.Reference | null = null;

async function fetchGame(gameId: string, callback: (a0: Game) => void, notFoundCallback: (a0: null) => void) {
  const logger = createLogger("🎲 fetch game");

  const user = firebase.auth().currentUser;
  if (!user) return logger("unable to get current user");

  if (gameRef) gameRef.off("value");
  gameRef = firebase.database().ref("games").child(gameId);

  gameRef.on("value", async (snapshot) => {
    const game: Game | null = snapshot.val();

    if (!game) {
      logger("not found");
      gameRef && gameRef.off("value");
      return notFoundCallback(null);
    }

    logger(`got game ${game.id} - is user added ${!!game.players[user.uid]}`);

    if (gameRef && !game.players[user.uid]) {
      logger(`adding player`);

      // update db
      gameRef.child(`players/${user.uid}`).set(user.uid);

      // add event
      dbAddEvent(game.id, { type: "PlayerJoined", uid: user.uid, id: "" });

      // just return the updated thing
      callback({ ...game, players: { ...game.players, [user.uid]: user.uid } });
    } else {
      callback(game);
    }
  });
}

function fetchUser(uid: string, callback: (a0: User) => void) {
  const logger = createLogger("👱 " + uid.slice(0, 5));
  firebase
    .database()
    .ref(`users/${uid}`)
    .once("value", (snapshot) => {
      logger("fetched");
      callback(snapshot.val());
    });
}

async function createGame(callback: (a0: Game) => void) {
  log("db: createGame");

  const user = firebase.auth().currentUser;
  if (!user) return log("unable to get current user");

  const game = await dbCreateGame(user);

  updateActiveGame(game.id);

  callback(game);
}

async function createPuzzle(callback: (a0: Puzzle) => void) {
  log("db: createPuzzle");

  const user = firebase.auth().currentUser;
  if (!user) return log("unable to get current user");

  const puzzle = await dbFetchRandomPuzzle();

  callback(puzzle);
}

async function dbFetchRandomPuzzle(): Promise<Puzzle> {
  const logger = createLogger("🧩 create puzzle");

  const boardSnap = await firebase.database().ref("solutions").orderByChild("random").limitToFirst(1).startAt(Math.random()).once("value");
  const boardVal: any = Object.values(boardSnap.val())[0];

  // logger("o" + boardVal);
  // console.log(boardVal);

  const puzzle: Puzzle = {
    id: String(boardVal.random).slice(2),
    board: BoardFn.fromString(String(boardVal.random).slice(2), boardVal.board),
    optimal: boardVal.length,
  };

  return puzzle;
}

async function fetchPuzzle(id: string, callback: (a0: Puzzle) => void) {
  const logger = createLogger("🧩 fetch puzzle");

  const boardSnap = await firebase.database().ref("solutions").child(id).once("value");
  const boardVal: any = boardSnap.val();

  logger(boardVal);

  if (boardVal) {
    const puzzle: Puzzle = {
      id,
      board: BoardFn.fromString(String(boardVal.random).slice(2), boardVal.board),
      optimal: boardVal.length,
    };
    callback(puzzle);
  } else {
    // @ts-ignore
    callback(null);
  }
}

async function dbCreateGame(user: firebase.User): Promise<Game> {
  log("db: dbCreateGame");

  const id = await createGameId();

  const game = newGame(id, user.uid);

  firebase
    .database()
    .ref()
    .update({
      [`games/${id}`]: game,
    });

  dbAddEvent(game.id, {
    type: "GameCreated",
    uid: user.uid,
    id: "",
  });

  dbAddEvent(game.id, {
    type: "PlayerJoined",
    uid: user.uid,
    id: "",
  });

  return game;
}

async function createGameId(): Promise<string> {
  const random = String(Math.floor(Math.random() * 1000000));

  const snapshot = await firebase.database().ref("games").child(random).once("value");

  return snapshot.exists() ? createGameId() : random;
}

async function submitMoves(gameId: string, moves: Move[]) {
  const logger = createLogger("🚀 sm");

  const user = firebase.auth().currentUser;
  if (!user) return log("unable to get current user");

  // 1. update best moves **IF** game is not finished
  gameTransaction(
    gameId,
    (game) => {
      if (!game.round) {
        return logger("round is null");
      }

      if (!movesValid(moves)) {
        return logger("invalid moves");
      }

      if (game.state.state != State.RoundInProgress && game.state.state != State.RoundFinishing) {
        return logger("cannot submit moves in state " + game.state.state);
      }

      const sequence: Sequence = {
        uid: user.uid,
        moves,
        timestamp: serverTimestamp(),
      };

      if (game.round.best) {
        // need to check if the current sequence is better
        if (game.round.best.moves.length > moves.length) {
          // the solution is better
          logger("better");
          return { ...game, round: { ...game.round, best: sequence } };
        } else {
          logger("worse");
          return game;
        }
      } else {
        // no best solution yet, happy to update
        logger("first");
        return { ...game, round: { ...game.round, best: sequence } };
      }
    },
    (snap) => {
      const logger = createLogger("🚀 sm/committed");

      // 2. call finish game to figure out what to do next
      finishGameOrRound(gameId);

      const game: Game = snap && snap.val();
      if (!game || !game.round) return;

      // raise event
      const sequence: Sequence = {
        uid: user.uid,
        moves,
        timestamp: serverTimestamp(),
      };

      const isOptimalSequnce = moves.length <= game.round.optimal;

      const isBestSequence = game.round.best ? user.uid == game.round.best.uid && movesIdentical(moves, game.round.best.moves) : true;

      const state = isOptimalSequnce ? SequenceState.Optimal : isBestSequence ? SequenceState.Best : SequenceState.Undistinguished;

      logger("sequence is " + state);

      // TODO: perhaps don't store state in this event (as we don't know the truth fo sure if the seq is best)
      // we need it so we can notify when other players submit a better move
      dbAddEvent(game.id, {
        type: "SequenceSubmitted",
        id: "",
        state,
        uid: user.uid,
        moves: sequence.moves,
      });
    }
  );
}

function movesIdentical(m1: Move[], m2: Move[]): boolean {
  if (m1.length !== m2.length) return false;
  return m1.reduce((identical: boolean, m: Move, i: number) => {
    return !identical ? false : moveIdentical(m, m2[i]);
  }, true);
}

function moveIdentical(m1: Move, m2: Move): boolean {
  return m1.color === m2.color && m1.direction === m2.direction;
}

function nudge(gameId: string): void {
  log("db: nudge");

  const user = firebase.auth().currentUser;
  if (!user) {
    return log("unable to get current user");
  }

  finishGameOrRound(gameId);
}

async function getGame(gameId: string): Promise<Game | null> {
  const snap = await firebase.database().ref(`games/${gameId}`).once("value");
  return snap.val();
}

const createLogger = (name: string) => {
  const ts = Date.now().toString().slice(10);
  const lg = (msg: string): undefined => {
    console.log(`${name}/${ts} ${msg}`);
    return;
  };
  console.log(`${name}/${ts}`);
  return lg;
};

function stateTransition(s1: State, s2: State, msg: string): string {
  return `${s1} 👉👉 ${s2} (${msg})`;
}

/*
transition from acrive game -> game finishing
*/
async function finishGameOrRound(gameId: string) {
  const logger = createLogger("⏰fgor");

  const logStateChange = (msg: string, g1: Game, g2: Game): Game => {
    logger(stateTransition(g1.state.state, g2.state.state, msg));
    return g2;
  };

  const offsetSnap = await firebase.database().ref(".info/serverTimeOffset").once("value");
  const offset = offsetSnap.val() || 0;

  gameTransaction(
    gameId,
    (game: Game): Game | undefined => {
      if (!game.round) {
        return logger(`nothing to do when round is null`);
      }

      if (!game.round.best) {
        return logger(`nothing to do when we haven't got a best solution`);
      }

      const roundId = game.round.id;
      const winnerUid = game.round.best.uid;

      // have we found the optimal solution?
      if (
        game.round.best.moves.length <= game.round.optimal &&
        (game.state.state === State.RoundInProgress || game.state.state === State.RoundFinishing)
      ) {
        const updatedGame = gameWithUpdatedWinner(roundId, winnerUid, game);
        return logStateChange("optimal solution", game, updatedGame);
      }

      // have we found _a_ solution?
      if (game.state.state === State.RoundInProgress) {
        const updatedGame = gameWithUpdatedState(State.RoundFinishing, game);
        return logStateChange("a solution", game, updatedGame);
      }

      if (game.state.state !== State.RoundFinishing) {
        return logger(`nothing to do in state ${game.state.state}`);
      }

      // has the timer expired
      const now = Date.now() + offset;
      const timeLeft = game.state.timestamp + game.config.roundFinishDelayMs - now;
      if (timeLeft > 0) {
        return logger(`nothing to do. still ${timeLeft / 1000}s left of round`);
      }

      // has someone else already won?
      if (game.scores[game.round.id]) {
        return logger(`round already won by ${game.scores[game.round.id].uid.slice(0, 3)}`);
      }

      // at this point we know the round is over
      const updatedGame = gameWithUpdatedWinner(roundId, winnerUid, game);
      return logStateChange("time", game, updatedGame);
    },
    (snap) => {
      if (!snap) return;
      const game: Game = snap.val();

      if (!game || !game.round || !game.round.best) return;

      if (game.state.state != State.GameFinished && game.state.state != State.RoundFinished) return;

      dbAddEvent(game.id, {
        type: "RoundWon",
        id: "",
        winner: game.round.best.uid,
        boardId: game.round.board.boardId,
        optimal: game.round.best.moves.length <= game.round.optimal,
      });
    }
  );
}

function gameWithUpdatedState(state: State, game: Game): Game {
  return {
    ...game,
    state: { state, timestamp: serverTimestamp() },
  };
}

function gameWithUpdatedStateAndWinner(points: number, state: State, roundId: string, winnerUid: string, game: Game): Game {
  return gameWithUpdatedState(state, {
    ...game,
    scores: { ...game.scores, [roundId]: {uid: winnerUid, points} },
  });
}

function gameWithUpdatedWinner(roundId: string, winnerUid: string, game: Game) {
  // todo: better guards here?
  const points = game.config.scoringType == ScoringType.OnePointPerGame ? 1 : (game.round?.best?.moves.length || 0)

  const score = Object.values(game.scores).reduce((score, {uid, points}) => {
    return uid === winnerUid ? score + points : score;
  }, 0);

  if (score + points >= game.config.numberOfPointsToWin) {
    deleteActiveGame(game.id);
    return gameWithUpdatedStateAndWinner(points, State.GameFinished, roundId, winnerUid, game);
  } else {
    updateActiveGame(game.id);
    return gameWithUpdatedStateAndWinner(points, State.RoundFinished, roundId, winnerUid, game);
  }
}

// function updateRoundFinished(game: Game, winnerUid: string, roundId: string): Game {
//   return {
//     ...game,
//     state: {
//       state: State.RoundFinished,
//       timestamp: serverTimestamp(),
//     },
//     players: {
//       ...game.players,
//       [winnerUid]: {
//         ...game.players[winnerUid],
//         wins: { ...game.players[winnerUid].wins, [roundId]: roundId },
//       },
//     },
//   };
// }

async function deleteActiveGame(gameId: string) {
  const ref = firebase.database().ref("active");
  const snap = await ref.once("value");
  const active = snap.val();
  if (active && active.gameId === gameId) {
    ref.remove();
  }
}

function updateActiveGame(gameId: string) {
  const active = {
    timestamp: serverTimestamp(),
    gameId,
  };
  firebase.database().ref("active").set(active);
}

// helpers

function gameTransaction(
  gameId: string,
  callback: (game: Game) => Game | undefined,
  onComplete?: (snap: firebase.database.DataSnapshot | null) => void
): void {
  // console.log("*** gameTransaction ***");

  const ref = firebase.database().ref("games").child(gameId);
  ref.transaction(
    (maybeGame) => {
      return maybeGame
        ? callback({
            ...maybeGame,
            scores: maybeGame.scores ? maybeGame.scores : {},
          })
        : maybeGame;
    },
    (err, committed, snap) => {
      !err && committed && onComplete && onComplete(snap);
    },
    false
  );
}

function movesValid(moves: any): boolean {
  return true;
}

export interface GameStateDB {
  timestamp: Object;
  state: State;
}

function serverTimestamp(): number {
  // @ts-ignore
  return firebase.database.ServerValue.TIMESTAMP;
}

function log(msg: string) {
  console.log(`[${Date.now()}] ${msg}`);
}

// paths
