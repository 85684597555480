import {
  User,
  Game,
  Puzzle,
  Event,
  MoveToSubmit,
  UserPrefs,
  RoundStartDelayChanged,
  NumRoundsToWinChanged,
  RoundFinishDelayChanged,
  RevealSolutionsChanged,
  ScoringTypeChanged,
  AddMessage,
} from "./types";

interface FromElm {
  fetchActiveGame: (msg: null, toElm: ToElm) => void;
  updateSoundPref: (msg: boolean, toElm: ToElm) => void;
  playInvalidMoveSound: (msg: null, toElm: ToElm) => void;
  playValidMoveSound: (msg: null, toElm: ToElm) => void;
  playBestSolutionSoundThisPlayer: (msg: null, toElm: ToElm) => void;
  playBestSolutionSoundOtherPlayer: (msg: null, toElm: ToElm) => void;
  playNotBestSolutionSound: (msg: null, toElm: ToElm) => void;
  copyToClipboard: (msg: string, toElm: ToElm) => void;
  loginWithNick: (msg: string, toElm: ToElm) => void;
  loginWithGoogle: (msg: null, toElm: ToElm) => void;
  submitMoves: (msg: MoveToSubmit, toElm: ToElm) => void;
  startNextRound: (msg: string, toElm: ToElm) => void;
  checkStartNextRound: (msg: string, toElm: ToElm) => void;
  createMultiPlayerGame: (msg: null, toElm: ToElm) => void;
  createSinglePlayerPuzzle: (msg: null, toElm: ToElm) => void;
  fetchGame: (msg: string, toElm: ToElm) => void;
  fetchPuzzle: (msg: string, toElm: ToElm) => void;
  fetchUser: (msg: string, toElm: ToElm) => void;
  nudge: (msg: string, toElm: ToElm) => void;
  prefsUpdated: (msg: UserPrefs, toElm: ToElm) => void;
  roundStartDelayChanged: (msg: RoundStartDelayChanged, toElm: ToElm) => void;
  numRoundsToWinChanged: (msg: NumRoundsToWinChanged, toElm: ToElm) => void;
  roundFinishDelayChanged: (msg: RoundFinishDelayChanged, toElm: ToElm) => void;
  revealSolutionsChanged: (msg: RevealSolutionsChanged, toElm: ToElm) => void;
  scoringTypeChanged: (msg: ScoringTypeChanged, toElm: ToElm) => void;
  addMessage: (msg: AddMessage, toElm: ToElm) => void;
  rematch: (msg: string, toElm: ToElm) => void;
  displayNotification: (msg: string, toElm: ToElm) => void;
  enableNotifications: (msg: null, toElm: ToElm) => void;
}

interface ToElm {
  userAuthenticated: (msg: User) => void;
  userNotAuthenticated: (msg: null) => void;
  gameFetched: (msg: Game) => void;
  singlePlayerPuzzleFetched: (msg: Puzzle) => void;
  userFetched: (msg: User) => void;
  gotServerTimeOffset: (msg: number) => void;
  tabPressed: (msg: number) => void;
  gameNotFound: (msg: null) => void;
  eventsFetched: (msg: Event[]) => void;
  eventAdded: (msg: Event) => void;
  gotActiveGame: (msg: string) => void;
}

export interface ElmApp {
  ports: {
    userAuthenticatedSub: { send: (msg: User) => void };
    userNotAuthenticatedSub: { send: (msg: null) => void };
    gameFetchedSub: { send: (msg: Game) => void };
    singlePlayerPuzzleFetchedSub: { send: (msg: Puzzle) => void };
    userFetchedSub: { send: (msg: User) => void };
    gotServerTimeOffsetSub: { send: (msg: number) => void };
    tabPressedSub: { send: (msg: number) => void };
    gameNotFoundSub: { send: (msg: null) => void };
    eventsFetchedSub: { send: (msg: Event[]) => void };
    eventAddedSub: { send: (msg: Event) => void };
    gotActiveGameSub: { send: (msg: string) => void };
    fetchActiveGamePort: { subscribe: (sub: (msg: null) => void) => void };
    updateSoundPrefPort: { subscribe: (sub: (msg: boolean) => void) => void };
    playInvalidMoveSoundPort: { subscribe: (sub: (msg: null) => void) => void };
    playValidMoveSoundPort: { subscribe: (sub: (msg: null) => void) => void };
    playBestSolutionSoundThisPlayerPort: {
      subscribe: (sub: (msg: null) => void) => void;
    };
    playBestSolutionSoundOtherPlayerPort: {
      subscribe: (sub: (msg: null) => void) => void;
    };
    playNotBestSolutionSoundPort: {
      subscribe: (sub: (msg: null) => void) => void;
    };
    copyToClipboardPort: { subscribe: (sub: (msg: string) => void) => void };
    loginWithNickPort: { subscribe: (sub: (msg: string) => void) => void };
    loginWithGooglePort: { subscribe: (sub: (msg: null) => void) => void };
    submitMovesPort: { subscribe: (sub: (msg: MoveToSubmit) => void) => void };
    startNextRoundPort: { subscribe: (sub: (msg: string) => void) => void };
    checkStartNextRoundPort: {
      subscribe: (sub: (msg: string) => void) => void;
    };
    createMultiPlayerGamePort: {
      subscribe: (sub: (msg: null) => void) => void;
    };
    createSinglePlayerPuzzlePort: {
      subscribe: (sub: (msg: null) => void) => void;
    };
    fetchGamePort: { subscribe: (sub: (msg: string) => void) => void };
    fetchPuzzlePort: { subscribe: (sub: (msg: string) => void) => void };
    fetchUserPort: { subscribe: (sub: (msg: string) => void) => void };
    nudgePort: { subscribe: (sub: (msg: string) => void) => void };
    prefsUpdatedPort: { subscribe: (sub: (msg: UserPrefs) => void) => void };
    roundStartDelayChangedPort: {
      subscribe: (sub: (msg: RoundStartDelayChanged) => void) => void;
    };
    numRoundsToWinChangedPort: {
      subscribe: (sub: (msg: NumRoundsToWinChanged) => void) => void;
    };
    roundFinishDelayChangedPort: {
      subscribe: (sub: (msg: RoundFinishDelayChanged) => void) => void;
    };
    revealSolutionsChangedPort: {
      subscribe: (sub: (msg: RevealSolutionsChanged) => void) => void;
    };
    scoringTypeChangedPort: {
      subscribe: (sub: (msg: ScoringTypeChanged) => void) => void;
    };
    addMessagePort: { subscribe: (sub: (msg: AddMessage) => void) => void };
    rematchPort: { subscribe: (sub: (msg: string) => void) => void };
    displayNotificationPort: {
      subscribe: (sub: (msg: string) => void) => void;
    };
    enableNotificationsPort: { subscribe: (sub: (msg: null) => void) => void };
  };
}

export const init = (app: ElmApp, fromElm: FromElm): ToElm => {
  const toElm = {
    userAuthenticated: (msg: User) => app.ports.userAuthenticatedSub.send(msg),
    userNotAuthenticated: (msg: null) =>
      app.ports.userNotAuthenticatedSub.send(msg),
    gameFetched: (msg: Game) => app.ports.gameFetchedSub.send(msg),
    singlePlayerPuzzleFetched: (msg: Puzzle) =>
      app.ports.singlePlayerPuzzleFetchedSub.send(msg),
    userFetched: (msg: User) => app.ports.userFetchedSub.send(msg),
    gotServerTimeOffset: (msg: number) =>
      app.ports.gotServerTimeOffsetSub.send(msg),
    tabPressed: (msg: number) => app.ports.tabPressedSub.send(msg),
    gameNotFound: (msg: null) => app.ports.gameNotFoundSub.send(msg),
    eventsFetched: (msg: Event[]) => app.ports.eventsFetchedSub.send(msg),
    eventAdded: (msg: Event) => app.ports.eventAddedSub.send(msg),
    gotActiveGame: (msg: string) => app.ports.gotActiveGameSub.send(msg),
  };
  if (typeof app.ports.fetchActiveGamePort !== "undefined") {
    app.ports.fetchActiveGamePort.subscribe((msg) =>
      fromElm.fetchActiveGame(msg, toElm)
    );
  }
  if (typeof app.ports.updateSoundPrefPort !== "undefined") {
    app.ports.updateSoundPrefPort.subscribe((msg) =>
      fromElm.updateSoundPref(msg, toElm)
    );
  }
  if (typeof app.ports.playInvalidMoveSoundPort !== "undefined") {
    app.ports.playInvalidMoveSoundPort.subscribe((msg) =>
      fromElm.playInvalidMoveSound(msg, toElm)
    );
  }
  if (typeof app.ports.playValidMoveSoundPort !== "undefined") {
    app.ports.playValidMoveSoundPort.subscribe((msg) =>
      fromElm.playValidMoveSound(msg, toElm)
    );
  }
  if (typeof app.ports.playBestSolutionSoundThisPlayerPort !== "undefined") {
    app.ports.playBestSolutionSoundThisPlayerPort.subscribe((msg) =>
      fromElm.playBestSolutionSoundThisPlayer(msg, toElm)
    );
  }
  if (typeof app.ports.playBestSolutionSoundOtherPlayerPort !== "undefined") {
    app.ports.playBestSolutionSoundOtherPlayerPort.subscribe((msg) =>
      fromElm.playBestSolutionSoundOtherPlayer(msg, toElm)
    );
  }
  if (typeof app.ports.playNotBestSolutionSoundPort !== "undefined") {
    app.ports.playNotBestSolutionSoundPort.subscribe((msg) =>
      fromElm.playNotBestSolutionSound(msg, toElm)
    );
  }
  if (typeof app.ports.copyToClipboardPort !== "undefined") {
    app.ports.copyToClipboardPort.subscribe((msg) =>
      fromElm.copyToClipboard(msg, toElm)
    );
  }
  if (typeof app.ports.loginWithNickPort !== "undefined") {
    app.ports.loginWithNickPort.subscribe((msg) =>
      fromElm.loginWithNick(msg, toElm)
    );
  }
  if (typeof app.ports.loginWithGooglePort !== "undefined") {
    app.ports.loginWithGooglePort.subscribe((msg) =>
      fromElm.loginWithGoogle(msg, toElm)
    );
  }
  if (typeof app.ports.submitMovesPort !== "undefined") {
    app.ports.submitMovesPort.subscribe((msg) =>
      fromElm.submitMoves(msg, toElm)
    );
  }
  if (typeof app.ports.startNextRoundPort !== "undefined") {
    app.ports.startNextRoundPort.subscribe((msg) =>
      fromElm.startNextRound(msg, toElm)
    );
  }
  if (typeof app.ports.checkStartNextRoundPort !== "undefined") {
    app.ports.checkStartNextRoundPort.subscribe((msg) =>
      fromElm.checkStartNextRound(msg, toElm)
    );
  }
  if (typeof app.ports.createMultiPlayerGamePort !== "undefined") {
    app.ports.createMultiPlayerGamePort.subscribe((msg) =>
      fromElm.createMultiPlayerGame(msg, toElm)
    );
  }
  if (typeof app.ports.createSinglePlayerPuzzlePort !== "undefined") {
    app.ports.createSinglePlayerPuzzlePort.subscribe((msg) =>
      fromElm.createSinglePlayerPuzzle(msg, toElm)
    );
  }
  if (typeof app.ports.fetchGamePort !== "undefined") {
    app.ports.fetchGamePort.subscribe((msg) => fromElm.fetchGame(msg, toElm));
  }
  if (typeof app.ports.fetchPuzzlePort !== "undefined") {
    app.ports.fetchPuzzlePort.subscribe((msg) =>
      fromElm.fetchPuzzle(msg, toElm)
    );
  }
  if (typeof app.ports.fetchUserPort !== "undefined") {
    app.ports.fetchUserPort.subscribe((msg) => fromElm.fetchUser(msg, toElm));
  }
  if (typeof app.ports.nudgePort !== "undefined") {
    app.ports.nudgePort.subscribe((msg) => fromElm.nudge(msg, toElm));
  }
  if (typeof app.ports.prefsUpdatedPort !== "undefined") {
    app.ports.prefsUpdatedPort.subscribe((msg) =>
      fromElm.prefsUpdated(msg, toElm)
    );
  }
  if (typeof app.ports.roundStartDelayChangedPort !== "undefined") {
    app.ports.roundStartDelayChangedPort.subscribe((msg) =>
      fromElm.roundStartDelayChanged(msg, toElm)
    );
  }
  if (typeof app.ports.numRoundsToWinChangedPort !== "undefined") {
    app.ports.numRoundsToWinChangedPort.subscribe((msg) =>
      fromElm.numRoundsToWinChanged(msg, toElm)
    );
  }
  if (typeof app.ports.roundFinishDelayChangedPort !== "undefined") {
    app.ports.roundFinishDelayChangedPort.subscribe((msg) =>
      fromElm.roundFinishDelayChanged(msg, toElm)
    );
  }
  if (typeof app.ports.revealSolutionsChangedPort !== "undefined") {
    app.ports.revealSolutionsChangedPort.subscribe((msg) =>
      fromElm.revealSolutionsChanged(msg, toElm)
    );
  }
  if (typeof app.ports.scoringTypeChangedPort !== "undefined") {
    app.ports.scoringTypeChangedPort.subscribe((msg) =>
      fromElm.scoringTypeChanged(msg, toElm)
    );
  }
  if (typeof app.ports.addMessagePort !== "undefined") {
    app.ports.addMessagePort.subscribe((msg) => fromElm.addMessage(msg, toElm));
  }
  if (typeof app.ports.rematchPort !== "undefined") {
    app.ports.rematchPort.subscribe((msg) => fromElm.rematch(msg, toElm));
  }
  if (typeof app.ports.displayNotificationPort !== "undefined") {
    app.ports.displayNotificationPort.subscribe((msg) =>
      fromElm.displayNotification(msg, toElm)
    );
  }
  if (typeof app.ports.enableNotificationsPort !== "undefined") {
    app.ports.enableNotificationsPort.subscribe((msg) =>
      fromElm.enableNotifications(msg, toElm)
    );
  }
  return toElm;
};

export default { init };
